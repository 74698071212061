import { documentDelegate } from "utils/events";

function run() {
  documentDelegate("click", "[data-copy-to-clipboard]", handleClick);
}

function handleClick(event) {
  const element = event.currentTarget;
  const text = element.dataset.clipboardText;
  const originalLabel = element.ariaLabel;

  const defaultIcon = element.querySelector("[data-target=default-icon]");
  const successIcon = element.querySelector("[data-target=success-icon]");
  const failureIcon = element.querySelector("[data-target=failure-icon]");

  function flipIcon(icon, { label }) {
    element.ariaLabel = label;
    defaultIcon.style.display = "none";
    icon.style.display = "block";
    setTimeout(() => {
      element.ariaLabel = originalLabel;
      defaultIcon.style.display = "block";
      icon.style.display = "none";
    }, "5000");
  }

  writeText(text)
    .then(() => {
      flipIcon(successIcon, { label: "Copied!" });
    })
    .catch(() => {
      flipIcon(failureIcon, { label: "Blocked by browser" });
    });
}

function writeText(text) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    return Promise.reject();
  }
}

window.addEventListener("DOMContentLoaded", run);
