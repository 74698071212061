const CHUNK_LENGTH = 4;
const CHUNK_CHARS = "a-zA-Z0-9";
const SEPARATOR = "-";
const DISALLOWED_CHAR = new RegExp(`[^${CHUNK_CHARS}${SEPARATOR}]`, "g");
const CHUNK_CHARS_GROUP = `[${CHUNK_CHARS}]`;
const LAST_CHUNK_TOO_LONG_MATCHER = new RegExp(
  `(${CHUNK_CHARS_GROUP}{${CHUNK_LENGTH}})(${CHUNK_CHARS_GROUP}+)$`
);

export function run() {
  const input = document.querySelector("[data-controller='MFA.BackupCodeEntry']");

  if (!input) return;

  function handleInput() {
    const value = input.value;
    let nextValue = value.replace(DISALLOWED_CHAR, "");

    if (value.match(LAST_CHUNK_TOO_LONG_MATCHER)) {
      nextValue = nextValue.replace(LAST_CHUNK_TOO_LONG_MATCHER, `$1${SEPARATOR}$2`);
    }

    input.value = nextValue;
  }

  input.addEventListener("input", handleInput);
}

window.addEventListener("DOMContentLoaded", run);
