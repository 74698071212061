export function run() {
  const container = document.querySelector("[data-controller='MFA.VerificationFormComponent']");

  if (!container) return;

  const { prepareCodeUrl } = container.dataset;

  const codeInputTarget = container.querySelector("[data-target=code-input]");

  verificationController({ container });
  prepareCodeController({ prepareCodeUrl, codeInputTarget, container });
  codeInputController(codeInputTarget);
}

function verificationController({ container }) {
  const { protectedForm } = container.dataset;

  const errorTarget = container.querySelector("[data-target=error]");

  function renderError(error) {
    if (error) {
      errorTarget.innerText = error;
      minionShow(errorTarget);
    } else {
      minionHide(errorTarget);
    }
  }

  container.addEventListener("ajax:complete", (e) => {
    const [xhr] = e.detail;

    const responseJSON = xhrJSON(xhr);

    if (responseJSON.error) {
      renderError(responseJSON.error);
    } else if (responseJSON.verified) {
      renderError(null);

      e.stopPropagation();

      const form = parseNode(protectedForm);
      form.style.display = "none";
      container.appendChild(form);
      form.submit();
    } else {
      throw new Error("what happened");
    }
  });
}

function prepareCodeController({ prepareCodeUrl, codeInputTarget, container }) {
  const resendContainer = container.querySelector("[data-target=resend-container]");
  const resendLink = resendContainer.querySelector("[data-target=resend-link]");

  function prepareCode() {
    minionHide(resendContainer);

    Rails.ajax({
      url: prepareCodeUrl,
      type: "get",
      dataType: "json",
      success: ({ current_code, resend_at }) => {
        if (current_code) codeInputTarget.value = current_code;

        if (resend_at) {
          executeAt(Date.parse(resend_at), () => minionShow(resendContainer));
        }
      },
    });
  }

  resendLink.addEventListener("click", (e) => {
    e.preventDefault();
    prepareCode();
  });

  prepareCode();
}

function codeInputController(codeInput) {
  let sanitizedCodeValue = codeInput.value;

  function sanitizeCodeValue(event) {
    const newCodeIsInvalid = event.data && event.data.match(/[^0-9]/);

    if (newCodeIsInvalid) {
      event.target.value = sanitizedCodeValue;
    }

    sanitizedCodeValue = event.target.value;
  }

  codeInput.addEventListener("input", sanitizeCodeValue);
}

function xhrJSON(xhr) {
  try {
    return JSON.parse(xhr.response);
  } catch (e) {
    return {};
  }
}

function executeAt(date, callback) {
  setTimeout(callback, date - Date.now());
}

function minionShow(node) {
  node.classList.remove("d-n");
}
function minionHide(node) {
  node.classList.add("d-n");
}

function parseNode(source) {
  const nodeContainer = document.createElement("div");
  nodeContainer.innerHTML = source;
  return nodeContainer.firstChild;
}

window.addEventListener("DOMContentLoaded", run);
