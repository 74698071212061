export function run() {
  const container = document.querySelector("[data-controller=MfaCodeInput]");

  if (!container) return;

  const codeInputTarget = container.querySelector("[data-target=code-input]");

  let sanitizedCodeValue = codeInputTarget.value;

  function sanitizeCodeValue(event) {
    const newCodeIsInvalid = event.data && event.data.match(/[^0-9]/);

    if (newCodeIsInvalid) {
      event.target.value = sanitizedCodeValue;
    }

    sanitizedCodeValue = event.target.value;
  }

  codeInputTarget.addEventListener("input", sanitizeCodeValue);
}
