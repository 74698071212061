var characterKinds = [/[a-z]/i, /[0-9]/, /[^a-z0-9]/i];

function validate(password) {
  password = password || "";

  var concerns = [];

  if (password.length < 10) {
    concerns.push("must be at least 10 characters");
  }

  if (
    characterKinds.filter(function (r) {
      return password.match(r);
    }).length === 1
  ) {
    concerns.push("must use a combination of letters, numbers, and special characters");
  }

  return concerns;
}

if (typeof module !== "undefined") {
  module.exports = validate;
}
