import { zxcvbn, pcValidate } from "planning_center_password.js.erb";

const minimumLengthToEvenCheckAPassword = 5;

function checkPasswords(password, confirmation, { zxcvbnDenyList }) {
  const strengthCheckResult = zxcvbn(password, zxcvbnDenyList);
  const strength = clamp((strengthCheckResult.score / 4) * 100, { min: 0, max: 100 });

  const ruleCheckResult = pcValidate(password);

  const isStrongEnough = ruleCheckResult.length === 0;
  const passwordsMatch = password === confirmation;
  const valid = passwordsMatch && isStrongEnough;
  const feedback = ruleCheckResult.join(". ");

  const hint = `
    <p class="d-b mt-1 mb-0">
      ${strengthCheckResult.feedback.warning || strengthCheckResult.feedback.suggestions.join(". ")}
    </p>
  `;

  let validationMessage = `
    <p class="d-b mt-1 mb-0">
      ${isStrongEnough && !valid ? "Passwords must match" : feedback}
    </p>
  `;

  if (valid) {
    validationMessage = null;
  } else if (password.length < minimumLengthToEvenCheckAPassword) {
    validationMessage = null;
  }

  return { valid, validationMessage, strength, hint };
}

function clamp(number, { min, max }) {
  if (number < min) {
    return min;
  } else if (number > max) {
    return max;
  } else {
    return number;
  }
}

function strengthLabel(strength, { password }) {
  if (password.trim() === "") {
    return "";
  }

  if (password.length < minimumLengthToEvenCheckAPassword) {
    return `too short`;
  }

  const labels = [
    [74, "weak"],
    [99, "ok"],
    [100, "strong"],
  ];

  return labels.find(([maxStrength]) => maxStrength >= strength)[1];
}

function strengthMeterClasses({ valid, label }) {
  const parameterizedLabel = label.split(/\s+/).join("-");
  return `${valid ? "valid" : "invalid"} ${parameterizedLabel}`;
}

export function run() {
  const container = document.querySelector("[data-controller=ChangePasswords]");

  if (!container) return;

  const zxcvbnDenyList = container.dataset.zxcvbnDenyList.split(",");

  const passwordTarget = container.querySelector("[data-target=password]");
  const passwordConfirmationTarget = container.querySelector("[data-target=password-confirmation]");
  const strengthMeterTarget = container.querySelector("[data-target=strength-meter]");
  const strengthLabelTarget = container.querySelector("[data-target=strength-label]");
  const strengthBarTarget = container.querySelector("[data-target=strength-bar]");
  const validationMessageTarget = container.querySelector("[data-target=validation-message]");

  const submitTarget = container.querySelector("[data-target=submit]");

  const initialStrengthMeterClasses = strengthMeterTarget.getAttribute("class");

  passwordTarget.addEventListener("input", updateMeter);
  passwordConfirmationTarget.addEventListener("input", updateMeter);

  updateMeter();

  function updateMeter() {
    const { valid, validationMessage, strength, hint } = checkPasswords(
      passwordTarget.value,
      passwordConfirmationTarget.value,
      { zxcvbnDenyList }
    );

    const label = strengthLabel(strength, { password: passwordTarget.value });

    if (valid) {
      validationMessageTarget.innerHTML = hint;
    } else {
      validationMessageTarget.innerHTML = validationMessage;
    }

    strengthMeterTarget.setAttribute(
      "class",
      initialStrengthMeterClasses + " " + strengthMeterClasses({ valid, label })
    );

    strengthLabelTarget.innerText = label;
    strengthBarTarget.style.width = `${strength}%`;

    if (valid) {
      submitTarget.removeAttribute("disabled");
    } else {
      submitTarget.setAttribute("disabled", "disabled");
    }
  }
}
