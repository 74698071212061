export function run() {
  const container = document.querySelector("[data-controller=SmsMfaCodeResendButton]");

  if (!container) return;

  const resendTarget = container.querySelector("[data-target=resend]");
  const resendAt = parseInt(container.dataset.resendAt, 10);

  setTimeout(showResend, resendAt - Date.now());

  function showResend() {
    resendTarget.style.display = "";
  }
}
