function forEach(list, cb) {
  return Array.prototype.forEach.call(list, cb);
}

function every(list, cb) {
  return Array.prototype.every.call(list, cb);
}

export function run() {
  const containers = document.querySelectorAll("[data-controller=RequireInputs]");

  forEach(containers, (container) => runInstance(container));
}

function runInstance(container) {
  const inputTargets = container.querySelectorAll("[data-target=required-input]");
  const managedInput = container.querySelector("[data-target=require-inputs-managed-input]");

  function handleChange() {
    const allHaveValues = every(inputTargets, (t) => t.value.trim().length > 0);
    managedInput.disabled = !allHaveValues;
  }

  forEach(inputTargets, (input) => {
    input.addEventListener("input", handleChange);
  });

  handleChange();
}
