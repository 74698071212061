// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable";
import "../components";
const Rails = require("@rails/ujs");

Rails.start();

global.Rails = Rails;

import { run as runChangePassword } from "controllers/change_password";
import { run as runSmsMfaCodeResendButton } from "controllers/sms_mfa_code_resend_button";
import { run as runCodeInput } from "controllers/mfa_code_input";
import { run as runTogglePasswordVisibility } from "controllers/toggle_password_visibility";
import { run as runRequireCheckbox } from "controllers/require_checkbox";
import { run as runRequireInputs } from "controllers/require_inputs";

require("@notainc/key-focus-visible");

const { documentDelegate } = require("../utils/events");

// https://github.com/Modernizr/Modernizr/blob/8cfeb97459c13a250029c8b4d71dfe34b3dc163a/feature-detects/svg.js

window.addEventListener("load", () => {
  try {
    if (
      !!document.createElementNS &&
      !!document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect
    ) {
      document.documentElement.classList.add("svg");
    }
  } catch (_error) {}
});

// helper shelves

function toggleHelperShelfById(event) {
  const node = document.getElementById(event.currentTarget.dataset.helperShelfId);
  if (!node) {
    return;
  }
  const nextHelperShelf = node.dataset.helperShelf === "closed" ? "open" : "closed";
  node.dataset.helperShelf = nextHelperShelf;
  if (node.dataset.helperShelf === "closed") {
    node.style.height = "0px";
  } else {
    node.style.height = node.scrollHeight + "px";
  }
}

documentDelegate("click", "[data-helper-shelf-id]", toggleHelperShelfById);

window.addEventListener("DOMContentLoaded", () => {
  runChangePassword();
  runSmsMfaCodeResendButton();
  runCodeInput();
  runTogglePasswordVisibility();
  runRequireCheckbox();
  runRequireInputs();
});
