// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

class DelegatedEventCallback {
  constructor({ selector, callback }) {
    this.selector = selector;
    this.callback = callback;
  }
}

let listenerTypes = [];
let delegatedEventCallbacks = {};

function walkUp(element, callback) {
  let elem = element;
  for (; elem && elem !== document; elem = elem.parentNode) {
    callback(elem);
  }
}

function buildAncestors(element) {
  const ancestors = [];

  walkUp(element, (e) => ancestors.push(e));

  return ancestors;
}

function makeListener(eventType) {
  return (event) => {
    const ancestors = buildAncestors(event.target);

    delegatedEventCallbacks[eventType].forEach((cb) => {
      for (let i = 0; i < ancestors.length; i++) {
        const element = ancestors[i];

        if (element.matches(cb.selector)) {
          cb.callback({ target: event.target, currentTarget: element });

          break;
        }
      }
    });
  };
}

export function documentDelegate(eventType, selector, callback) {
  if (!listenerTypes.hasOwnProperty(eventType)) {
    document.addEventListener(eventType, makeListener(eventType));

    listenerTypes[eventType] = true;
  }

  if (!delegatedEventCallbacks.hasOwnProperty(eventType)) {
    delegatedEventCallbacks[eventType] = [];
  }

  delegatedEventCallbacks[eventType].push(new DelegatedEventCallback({ selector, callback }));
}
